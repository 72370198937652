<template>
  <section class="time-line1" :style="{ height: approvalList?.length ? '80px' : '48px' }">
    <template v-for="(item, index) in approvalList" :key="index">
      <section class="time-sec">
        <div class="time-info">
          <section class="time-block" :style="showPoint ? 'cursor: pointer;' : ''">
            <div class="time-avatar">
              <img
                class="avatar"
                :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
              />
              <i class="iconfont icon-cha1 remove-arrow" v-if="isAdd" @click="$emit('delete-approval', index)" />
              <i class="iconfont icon-cha1 remove-arrow" v-if="showPoint" @click="$emit('delete-approval', index)" />
            </div>
            <div
              class="line"
              :style="
                isAdd
                  ? approvalList.length == index + 1
                    ? 'border-color:#e9e9e9'
                    : 'border-color:#0A7BFF'
                  : approvalList.length == index + 1
                  ? 'border:none'
                  : 'border-color:#0A7BFF'
              "
            />
          </section>
          <p class="time-name" :title="item.name || item.staffName">{{ item.name || item.staffName }}</p>
        </div>
      </section>
    </template>
    <section v-if="isAdd" key="ss" style="padding:4px 0">
      <a-button class="add-btn" @click="$emit('open-approval-modal')"> + </a-button>
    </section>
    <!-- <a-timeline>
      <template v-for="(item, index) in approvalList" :key="index">
        <a-timeline-item color="red">
          <section class="time-sec">
            <div class="time-info">
              <div class="time-avatar">
                <img
                  class="avatar"
                  :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
                />
                <i class="iconfont icon-cha1 remove-arrow" v-if="isAdd" @click="$emit('delete-approval', index)" />
              </div>

              <p class="time-name">{{ item.name || item.staffName }}</p>
            </div>
          </section>
        </a-timeline-item>
      </template>
      <a-timeline-item v-if="isAdd" color="red" key="ss">
        <a-button class="add-btn" @click="$emit('open-approval-modal')"> + </a-button>
      </a-timeline-item>
    </a-timeline> -->
  </section>
</template>

<script>
import { approvalStatusObj, archiveStatusObj } from '@/utils/constData'
export default {
  name: 'approval-staff-time-list',
  props: {
    approvalList: {
      type: Array,
      default: () => []
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    showPoint: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      approvalStatusObj,
      archiveStatusObj
    }
  }
}
</script>

<style lang="less" scoped>
.time-line1 {
  display: flex;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    background: #f5f5f5;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #c6c6c6;
    border-radius: 4px;
  }
  .time-sec {
    display: flex;
    height: 70px;
    .time-info {
      padding: 4px 0;
      .time-block {
        display: flex;
        align-items: center;
      }
      .time-avatar {
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-bottom: 4px;
        position: relative;
        .avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .remove-arrow {
          position: absolute;
          top: -2px;
          right: 0px;
          color: #c3161c;
          font-size: 12px;
          background: #ffffff;
          height: 12px;
          line-height: 12px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      .line {
        width: 40px;
        height: 1px;
        margin: 0 8px;
        border-bottom: 1px solid #e9e9e9;
      }
    }
    .time-name {
      display: block;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      transform: translateY(5px);
      width: 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .add-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e9e9e9;
    font-size: 18px;
    text-align: center;
    padding: 0;
    color: #666666;
    border: none;
  }
}
</style>
